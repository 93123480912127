import React from 'react';
import styled from 'styled-components';
import {message} from 'antd';
import {getOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import {
  getUserStateLabel,
  getUserSubstateLabel,
  getUserInformToLabel,
} from '../../Utils/UserUtil';
import {formatDateStr} from '../../Utils/TimeUtil';
import {App} from 'styled-icons/bootstrap';

function AdminTest(props) {
  return <Wrapper></Wrapper>;
}

async function generateExcelFile(name, headers, records) {
  if (!window.XLSX) {
    console.log('no XLSX');
    return;
  }

  try {
    AppActions.setLoading(true);
    const wb = window.XLSX.utils.book_new();
    const ws = window.XLSX.utils.aoa_to_sheet([headers, ...records]);

    window.XLSX.utils.book_append_sheet(wb, ws, name);

    const wbout = window.XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'array',
      cellStyles: true,
      bookImages: true,
    });

    const objUrl = URL.createObjectURL(
      new Blob([wbout], {type: 'application/octet-stream'}),
    );

    await AppActions.delay(600);
    message.success('成功創建下載連結');

    return objUrl;
  } catch (err) {
    console.warn(err);
  } finally {
    AppActions.setLoading(false);
  }
}

const Wrapper = styled.div`
  padding: 20px;

  & > section {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export default AdminTest;
